import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//设置推广人
export const setPromotionExternal = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promotion/external/set",
  });

//推广设置查询
export const getPromotionExternalList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promotion/external/list",
  });

//推广佣金查询
export const getPromotionExternalUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promotion/external/user/list",
  });

//推广佣金Excel
export const exportPromotionExternalUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/promotion/external/user/list`,
  });

//用户推广查询
export const getPromoteUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/user/list",
  });

//用户推广导出
export const exportPromoteUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/promote/user/export`,
  });

//用户推广奖励设置列表
export const getPromoteUserCfgList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/user/cfg/list",
  });

//用户推广奖励设置
export const setPromoteUserAward = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/user/award/set",
  });

//用户推广奖励记录
export const getPromoteUserAwardList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/user/award/list",
  });

//用户推广奖励记录导出
export const exportPromoteUserAward = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/promote/user/award/export`,
  });

//用户奖励审核
export const setPromoteUserAudit = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/user/audit",
  });

//博主推广查询
export const getPromoteBloggerUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/blogger/user/list",
  });

//博主推广导出
export const exportPromoteBloggerUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/promote/blogger/user/export`,
  });

//博主奖励设置列表
export const getPromoteBloggerCfgList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/blogger/cfg/list",
  });

//博主推广奖励设置
export const setPromoteBloggerCfg = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/blogger/cfg/set",
  });

//博主推广奖励记录
export const getPromoteBloggerAwardList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/blogger/award/list",
  });

//博主推广奖励记录导出
export const exportPromoteBloggerAward = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/promote/blogger/award/export`,
  });

//博主推广奖励设置
export const setPromoteBloggerUserAudit = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/promote/blogger/user/audit",
  });
