
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { exportPromotionExternalUserList, getPromotionExternalUserList } from "@/api/request/promotion";

//组件
@Component({
  name: "Promotion",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //数据
  private total: number = 0; //总数
  private countryList: any[] = [];
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    row: 20,
    page: 1,

    //临时数据
    useridStr: "", //用户ID
    reg_time: [], //注册时间
    recharge_time: [], //充值时间
    external_pidStr: "", //推广上级ID

    //数据
    country: "",
    excel: false, //是否导出Excel
    userid: undefined, //用户ID
    external_pid: undefined, //推广上级ID
    reg_end_time: "", //注册结束时间
    reg_start_time: "", //注册开始时间
    recharge_end_time: "", //充值开始时间
    recharge_start_time: "", //充值结束时间
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.reg_time && this.listQuery.reg_time.length == 2) {
      this.listQuery.reg_end_time = `${this.listQuery.reg_time[1]} 23:59:59`;
      this.listQuery.reg_start_time = `${this.listQuery.reg_time[0]} 00:00:00`;
    } else {
      this.listQuery.reg_end_time = "";
      this.listQuery.reg_start_time = "";
    }

    //警报时间赋值
    if (this.listQuery.recharge_time && this.listQuery.recharge_time.length == 2) {
      this.listQuery.recharge_end_time = `${this.listQuery.recharge_time[1]} 23:59:59`;
      this.listQuery.recharge_start_time = `${this.listQuery.recharge_time[0]} 00:00:00`;
    } else {
      this.listQuery.recharge_end_time = "";
      this.listQuery.recharge_start_time = "";
    }

    //其他赋值
    this.listQuery.excel = false;
    this.listQuery.userid = this.listQuery.useridStr == "" ? undefined : Number(this.listQuery.useridStr);
    this.listQuery.external_pid = this.listQuery.external_pidStr == "" ? undefined : Number(this.listQuery.external_pidStr);

    //获取数据
    const { data } = await getPromotionExternalUserList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //导出处理
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.reg_time && this.listQuery.reg_time.length == 2) {
      this.listQuery.reg_end_time = `${this.listQuery.reg_time[1]} 23:59:59`;
      this.listQuery.reg_start_time = `${this.listQuery.reg_time[0]} 00:00:00`;
    } else {
      this.listQuery.reg_end_time = "";
      this.listQuery.reg_start_time = "";
    }

    //警报时间赋值
    if (this.listQuery.recharge_time && this.listQuery.recharge_time.length == 2) {
      this.listQuery.recharge_end_time = `${this.listQuery.recharge_time[1]} 23:59:59`;
      this.listQuery.recharge_start_time = `${this.listQuery.recharge_time[0]} 00:00:00`;
    } else {
      this.listQuery.recharge_end_time = "";
      this.listQuery.recharge_start_time = "";
    }

    //其他赋值
    this.listQuery.excel = true;
    this.listQuery.userid = this.listQuery.useridStr == "" ? undefined : Number(this.listQuery.useridStr);
    this.listQuery.external_pid = this.listQuery.external_pidStr == "" ? undefined : Number(this.listQuery.external_pidStr);

    //获取数据
    const { data } = await exportPromotionExternalUserList(this.listQuery);

    //保存数据
    saveAs(data, "推广佣金记录");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
